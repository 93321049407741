import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from '../../components/Layout'

const DownloadsPage = () => {
  const data = useStaticQuery(graphql`
  {
    minutes:
        allFile(
            filter: { sourceInstanceName: { eq: "minutes" } }
            sort: {
                fields: [ name ]
                order: DESC
            }
        ) {
            edges {
                node {
                    publicURL
                    name
                }
            }
        }
    presentations: 
        allFile(
            filter: { sourceInstanceName: { eq: "presentations" } }
            sort: {
                fields: [ name ]
                order: DESC
            }
        ) {
            edges {
                node {
                    publicURL
                    name
                }
            }
        }
    }
  `);
  let yearBreaks = {};
  data.minutes.edges.map((file, index) => {
    let fileAsDate = new Date(`${file.node.name}T00:00:00`).getFullYear();
    if (!yearBreaks[fileAsDate]) {
        yearBreaks[fileAsDate] = [];
    }
    yearBreaks[fileAsDate].push(file);
  });
  return (
    <Layout>
      <section className="section section--gradient">
            <div className="container">
                <div className="columns">
                    <div className="column is-10 is-offset-1">
                        <div className="section">
                            <h2 className="title is-size-3 has-text-weight-bold is-bold-light">
                                Minutes and Meeting Presentations
                            </h2>
                            <p>
                               Below you will find all of the approved meeting minutes and presentations from past gatherings.
                               Please click the link to download the PDFs, Powerpoints, or Word documents.
                               If you have questions, do not hesitate to contact us using the <a href="/contact">contact page!</a>
                            </p>
                            <br/>
                            <div className="content">
                                <div className="section minutes">
                                    <h3>Minutes</h3>
                                    <ul>
                                        {Object.keys(yearBreaks).reverse().map((year, index) => {
                                            return (
                                                <li key={`years-${index}`}>
                                                    <h4>{year}</h4>
                                                    <ul>
                                                    {yearBreaks[year].map((file, index) => {
                                                        return (<li key={`minutes-${year}-${index}`}>
                                                            <a href={file.node.publicURL} download>
                                                                {new Date(`${file.node.name}T00:00:00`).toLocaleDateString("en-US", {year: "numeric", month: "long", day: "numeric"})}
                                                            </a>
                                                        </li>);
                                                    })}
                                                    </ul>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </div>
                                <div className="section presentations">
                                    <h3>Presentations</h3>
                                    <ul>
                                        {data.presentations.edges.map((file, index) => {
                                        return (
                                            <li key={`presentations-${index}`}>
                                                <a href={file.node.publicURL} download>
                                                    {file.node.name.replace(/_/g, " ")}
                                                </a>
                                            </li>
                                        )
                                        })}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </Layout>
  )
}
export default DownloadsPage